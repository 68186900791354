<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <b-overlay :show="showOverlay" rounded="sm">
      <b-card style="max-width: 100%; width: 100%" class="mb-2">
        <b-tabs content-class="mt-3" pills>
          <b-tab :title="$t('COMMON.MAIN')" active>
            <b-card-body>
              <b-form-group
                id="field-name"
                label-cols-lg="2"
                :label="$t('COMMON.NAME')"
                label-for="field-name"
              >
                <b-form-input
                  id="field-name-input"
                  v-model="dto.name"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="field-title"
                label-cols-lg="2"
                :label="$t('COMMON.TITLE')"
                label-for="field-title-input"
              >
                <b-form-input
                  id="field-title-input"
                  v-model="dto.title"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="field-body"
                label-cols-lg="2"
                :label="$t('TEMPLATES.BODY')"
                label-for="field-body-input"
              >
                <b-form-textarea
                  id="field-body-input"
                  v-model="dto.body"
                ></b-form-textarea>
              </b-form-group>

              <b-form-group
                :label="$t('COMMON.TYPE')"
                label-for="template-type"
                label-cols-lg="2"
              >
                <b-form-select
                  v-model="dto.templateTypeId"
                  id="template-type"
                  :options="templateTypes"
                >
                </b-form-select>
              </b-form-group>

              <b-form-group
                id="field-locale"
                label-cols-lg="2"
                :label="$t('TEMPLATES.LOCALE')"
                label-for="field-locale-input"
              >
                <b-form-input
                  id="field-locale-input"
                  v-model="dto.locale"
                ></b-form-input>
              </b-form-group>
            </b-card-body>
          </b-tab>
        </b-tabs>

        <div style="float: right">
          <b-button variant="success" v-on:click="onSaveClicked">{{
            $t("COMMON.SAVE")
          }}</b-button>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { API_REQUEST } from "@/core/services/store/api.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "proeprty",
  components: {},
  data() {
    return {
      save: {
        resource: "/platform/api/template/save",
        requestType: "POST",
        requestParams: {},
      },

      dto: {
        id: null,
        name: null,
        body: null,
        locale: null,
        title: null,
        templateTypeId: null,
        templateTypeName: null,
      },

      templateTypesRequest: {
        resource:
          "/platform/api/dictionary/com.gracelogic.platform.notification.model.TemplateType",
        requestType: "GET",
        requestParams: {},
      },
      templateTypes: [],
      createNewStr: this.$t("TEMPLATES.CREATE_NEW_TEMPLATE"),
      editStr: this.$t("TEMPLATES.EDIT_TEMPLATE"),
      showOverlay: true,
    };
  },

  mounted() {
    let title = !this.$route.params.id ? this.createNewStr : this.editStr;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.TEMPLATES"), route: "/templates" },
      { title: title },
    ]);
  },

  created: async function () {
    if (this.$route.params.id != null) {
      await this.$store
        .dispatch(API_REQUEST, {
          resource: "/platform/api/template/" + this.$route.params.id,
          requestType: "GET",
        })
        .then((response) => (this.dto = response))
        .catch(this.onError);
    }

    this.showOverlay = false;

    this.loadTemplateTypes();
  },

  methods: {
    loadTemplateTypes: function () {
      this.$store
        .dispatch(API_REQUEST, this.templateTypesRequest)
        .then((response) => {
          for (const value of response) {
            this.templateTypes.push({
              value: value.id,
              text: value.nameLocalized,
            });
          }
        })
        .catch(this.onError);
    },

    onSaveClicked: function () {
      const request = this.save;
      request.dto = this.dto;

      this.$store
        .dispatch(API_REQUEST, request)
        .then((response) => {
          this.dto.id = response.id;

          this.$bvToast.toast(this.$t("COMMON.SAVED_SUCCESSFULLY"), {
            title: this.$t("COMMON.SUCCESS"),
            variant: "success",
            autoHideDelay: 5000,
          });
        })
        .catch(this.onError);
    },

    onError: function (response) {
      if (response && response.config) response = response.data;

      this.$bvToast.toast(
        response && response.message
          ? response.message
          : this.$t("COMMON.UNKNOWN_ERROR"),
        {
          title: this.$t("COMMON.ERROR"),
          variant: "danger",
          autoHideDelay: 5000,
        }
      );
    },
  },
};
</script>
<style>
.hidden {
  display: none;
}
.form-group {
  margin-bottom: 1rem;
}
</style>
